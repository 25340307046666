import React from 'react'
import { x } from '@xstyled/styled-components'
import {
  Chessboard,
  getConfig,
  generateRandomPawns
} from '@pool/components/chessboard/Chessboard'
import { useInView } from 'react-intersection-observer'
import { Link } from 'react-router-dom'

const config = getConfig({
  cell_height: 30,
  cell_width: 20,
  cell_amount_x: 140,
  cell_amount_y: 7
})

const pawnsConfig = {
  total: 20,
  ...config
}

const SPEED = 3

export function FooterChess() {
  const { ref, inView } = useInView({
    threshold: 0
  })

  const [coordinates, setCoordinates] = React.useState(
    generateRandomPawns(pawnsConfig)
  )
  const fpsInterval = React.useRef()
  const then = React.useRef()
  const startTime = React.useRef()
  const requestRef = React.useRef()

  React.useEffect(() => {
    function animate() {
      requestRef.current = requestAnimationFrame(animate)
      const now = Date.now()
      const elapsed = now - then.current
      if (elapsed > fpsInterval.current) {
        then.current = now - (elapsed % fpsInterval.current)
        setCoordinates(generateRandomPawns(pawnsConfig))
      }
    }

    function startAnimating(fps) {
      fpsInterval.current = 1000 / fps
      then.current = Date.now()
      startTime.current = then
      animate()
    }
    if (inView) {
      startAnimating(SPEED)
    }
    return () => cancelAnimationFrame(requestRef.current)
  }, [inView])

  return (
    <x.div>
      <x.div position="relative" ref={ref} overflow="hidden" h="212px">
        <x.div position="absolute" mx="auto" left="0" right="0" w="2802px">
          <Chessboard config={config} coordinates={coordinates} />
        </x.div>
      </x.div>
      <x.div
        px={{ _: '30px', md: '60px' }}
        w={{ _: '100%', maxWidth: 'midWidth' }}
        mx="auto"
        alignItems="center"
        display="flex"
        h="40px">
        <x.p
          fontSize={{ _: '12px', xl: '14px' }}
          color="lime"
          opacity="0.4"
          lineHeight="180%">
          Copyright © {new Date().getFullYear()} LinkPool. All rights reserved.
        </x.p>
        <x.a
          ml="3"
          as={Link}
          to="/brand-assets"
          fontSize={{ _: '12px', xl: '14px' }}
          color="lime"
          opacity="0.4"
          lineHeight="180%">
          Brand Assets
        </x.a>
      </x.div>
    </x.div>
  )
}
