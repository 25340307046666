import React from 'react'
import { LandingPage } from './components/landingPage/LandingPage'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { BrandAssets } from './components/brandAssets/BrandAssets'

const router = createBrowserRouter([
  {
    path: '/',
    element: <LandingPage />
  },
  {
    path: 'brand-assets',
    element: <BrandAssets />
  },
  {
    path: '*',
    element: <LandingPage />
  }
])

export const App = () => {
  return <RouterProvider router={router} />
}
