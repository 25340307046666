import React from 'react'
import {
  ThemeProvider,
  Preflight,
  ColorModeProvider
} from '@xstyled/styled-components'
import { theme, GlobalStyles } from './theme'

export const ThemeProviders = ({ children }) => (
  <ThemeProvider theme={theme}>
    <ColorModeProvider>
      <>
        <Preflight />
        <GlobalStyles />
        {children}
      </>
    </ColorModeProvider>
  </ThemeProvider>
)

export const Providers = ({ children }) => {
  return <ThemeProviders>{children}</ThemeProviders>
}
