import React from 'react'
import { x } from '@xstyled/styled-components'
import { Button } from '@pool/components/button/Button'
import { Navbar } from '@pool/components/navbar/Navbar'
import { ScrollRestoration } from 'react-router-dom'

const Logos = ({ filename, name, imageHeight, color = 'white', ...props }) => {
  return (
    <x.div
      h="200"
      border="default"
      borderColor="#62856C"
      borderRadius="5px"
      px="10"
      position="relative"
      {...props}>
      <x.div
        display="flex"
        justifyContent="center"
        alignItems="center"
        h="100%">
        <x.img
          h={imageHeight}
          src={`/brand-assets/${filename}.png`}
          alt={name}
        />
      </x.div>
      <x.div position="absolute" bottom="25px" right="25px" spaceX="2">
        <x.a
          color={color}
          textDecoration={{ _: 'none', hover: 'underline' }}
          href={`/brand-assets/${filename}.png`}
          download={`${filename}.png`}>
          PNG
        </x.a>
        <x.a
          color={color}
          textDecoration={{ _: 'none', hover: 'underline' }}
          href={`/brand-assets/${filename}.svg`}
          download={`${filename}.svg`}>
          SVG
        </x.a>
      </x.div>
    </x.div>
  )
}

const Colors = ({
  name,
  color = 'white',
  bg,
  hex,
  rgb,
  cmyk,
  pms,
  ...props
}) => {
  return (
    <x.div
      border="default"
      borderColor="transparent"
      borderRadius="5px"
      minHeight="90px"
      display="flex"
      bg={bg}
      {...props}>
      <x.div mt={{ _: '0', xl: 'auto' }} px="8" py="8">
        <x.h1 text="md" fontWeight="600" color={color} mb="3">
          {name}
        </x.h1>
        <x.div color={color}>
          <x.p>
            <strong>HEX</strong> {hex}
          </x.p>
          {rgb && (
            <x.p>
              <strong>RGB</strong> {rgb}
            </x.p>
          )}
          {cmyk && (
            <x.p>
              <strong>CMYK</strong> {cmyk}
            </x.p>
          )}
          {pms && (
            <x.p>
              <strong>PMS</strong> {pms}
            </x.p>
          )}
        </x.div>
      </x.div>
    </x.div>
  )
}

export const BrandAssets = () => {
  return (
    <x.main>
      <Navbar nav={false} />
      <x.section
        mx="auto"
        mt={{
          _: 0,
          lg: 8
        }}
        mb="32"
        w={{ _: '100%', xl: '1252px' }}
        position="relative">
        <x.div mx={{ _: 6, lg: 6 }} spaceY="160px" mt="200px">
          <x.div>
            <x.h2 color="lime" fontSize="30px" fontWeight="600" mb="10">
              Logos
            </x.h2>
            <x.div
              display="grid"
              gridTemplateColumns={{ _: 1, md: 3 }}
              gap="20px">
              <Logos
                filename="linkpool-logotype-og"
                imageHeight="40px"
                bg="purpleMedium"
              />
              <Logos
                filename="linkpool-logotype-white"
                imageHeight="40px"
                bg="black"
                color="white"
                borderColor="black"
              />
              <Logos
                filename="linkpool-logotype-dark"
                imageHeight="40px"
                bg="white"
                color="black"
                borderColor="white"
              />

              <Logos
                filename="linkpool-logotype-og-vert"
                imageHeight="80px"
                bg="purpleMedium"
              />
              <Logos
                filename="linkpool-logotype-white-vert"
                imageHeight="80px"
                bg="black"
                color="white"
                borderColor="black"
              />
              <Logos
                filename="linkpool-logotype-dark-vert"
                imageHeight="80px"
                bg="white"
                color="black"
                borderColor="white"
              />

              <Logos
                filename="linkpool-logo-og"
                imageHeight="40px"
                bg="purpleMedium"
              />
              <Logos
                filename="linkpool-logo-white"
                imageHeight="40px"
                bg="black"
                color="white"
                borderColor="black"
              />
              <Logos
                filename="linkpool-logo-dark"
                imageHeight="40px"
                bg="white"
                color="black"
                borderColor="white"
              />
            </x.div>
            <x.div textAlign="center" my="10">
              <Button
                variant="vandog"
                size="md"
                fontWeight="600"
                as="a"
                href="/brand-assets/linkpool-brand-assets.zip"
                download="sdl-brand-assets.zip">
                Download Logo Assets
              </Button>
            </x.div>
          </x.div>

          <x.div>
            <x.h2 color="lime" fontSize="30px" fontWeight="600" mb="10">
              Color Palette
            </x.h2>
            <x.div
              w="100%"
              display="grid"
              gap="0"
              gridTemplateColumns={{ _: 1, xl: '1fr 0.5fr 1fr' }}
              h={{ _: 'auto', md: 'auto', xl: '420px' }}>
              <Colors
                name="Space"
                hex="#093331"
                borderColor="lime"
                bg="space"
                color="lime"
                h="100%"
              />

              <Colors
                name="Lime"
                hex="#E5FFC5"
                borderColor="lime"
                bg="lime"
                color="black"
                h="100%"
              />

              <x.div
                display="flex"
                justifyContent="space-between"
                flexDirection="column">
                <Colors
                  flexGrow="1"
                  name="Grass Green"
                  hex="#359A77"
                  borderColor="grassGreen"
                  bg="grassGreen"
                  color="lime"
                  w="100%"
                />
                <x.div display={{ _: 'block', xl: 'flex' }} flexGrow="1">
                  <Colors
                    flexGrow="1"
                    height="100%"
                    name="Black Green"
                    hex="#041615"
                    borderColor="blackGreen"
                    bg="blackGreen"
                    color="lime"
                  />
                  <Colors
                    flexGrow="1"
                    height="100%"
                    name="Light Green"
                    hex="#73C4A8"
                    borderColor="lightGreen"
                    bg="lightGreen"
                    color="blackGreen"
                  />
                </x.div>
              </x.div>
            </x.div>
          </x.div>

          <x.div>
            <x.h2 color="lime" fontSize="30px" fontWeight="600" mb="10">
              Typography
            </x.h2>
            <x.div
              bg="blackGreen"
              w="100%"
              display="grid"
              gap="0"
              gridTemplateColumns={{ _: 1, xl: 3 }}
              h={{ _: 'auto', md: 'auto', xl: '420px' }}
              borderRadius="5px"
              p={{ _: 6, xl: '85px' }}>
              <x.div>
                <x.p
                  h="180px"
                  color="lime"
                  fontSize="35px"
                  fontWeight="600"
                  lineHeight="117%">
                  Largest collocated infrastructure provider in Web3
                </x.p>
                <x.div>
                  <x.p
                    fontSize="20px"
                    fontWeight="600"
                    lineHeight="117%"
                    color="lime"
                    mb="3">
                    Intern
                  </x.p>
                  <x.p fontSize="16px" fontWeight="400" color="lightGreen">
                    SemiBold
                  </x.p>
                </x.div>
              </x.div>
              <x.div>
                <x.p
                  h="180px"
                  color="lime"
                  fontSize="18px"
                  fontWeight="500"
                  lineHeight="131%">
                  Supporting all leading Blockchain networks, LinkPool can scale
                  without limitation while providing high-throughput And
                  resilience.
                </x.p>
                <x.div>
                  <x.p
                    fontSize="20px"
                    fontWeight="600"
                    lineHeight="117%"
                    color="lime"
                    mb="3">
                    Intern
                  </x.p>
                  <x.p fontSize="16px" fontWeight="400" color="lightGreen">
                    Medium
                  </x.p>
                </x.div>
              </x.div>
              <x.div>
                <x.p
                  h="180px"
                  color="lime"
                  fontSize="13px"
                  fontWeight="400"
                  lineHeight="155%">
                  LinkPool secures $100M+ of assets throughout the various
                  networks that it operates on. We continuously expand our
                  validating operation to boost decentralisation and security
                  within the Web3 industry.
                </x.p>
                <x.div>
                  <x.p
                    fontSize="20px"
                    fontWeight="600"
                    lineHeight="117%"
                    color="lime"
                    mb="3">
                    Intern
                  </x.p>
                  <x.p fontSize="16px" fontWeight="400" color="lightGreen">
                    Regular
                  </x.p>
                </x.div>
              </x.div>
            </x.div>
          </x.div>

          <x.div>
            <x.h2 color="lime" fontSize="30px" fontWeight="600" mb="10">
              Graphics
            </x.h2>
            <x.div
              // display="flex"
              w="100%"
              display="grid"
              gap="0"
              gridTemplateColumns={{ _: 1, xl: '3fr 1fr 1fr 1fr' }}>
              <x.div
                border="default"
                borderColor="#62856C"
                borderRadius="5px"
                position="relative">
                <x.img src={`/brand-assets/g1.png`} height="400px" w="100%" />
              </x.div>
              <x.div
                border="default"
                borderColor="#62856C"
                borderRadius="5px"
                position="relative">
                <x.div
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  h="100%"
                  p="6">
                  <x.img src={`/brand-assets/g2.png`} height="80px" />
                </x.div>
              </x.div>
              <x.div
                border="default"
                borderColor="#62856C"
                borderRadius="5px"
                position="relative"
                p="6">
                <x.div
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  h="100%">
                  <x.img src={`/brand-assets/g3.png`} height="80px" />
                </x.div>
              </x.div>
              <x.div
                border="default"
                borderColor="#62856C"
                borderRadius="5px"
                position="relative"
                p="6">
                <x.div
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  h="100%">
                  <x.img src={`/brand-assets/g4.png`} height="80px" />
                </x.div>
              </x.div>
            </x.div>
          </x.div>
        </x.div>
      </x.section>
      <ScrollRestoration />
    </x.main>
  )
}
