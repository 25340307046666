import React from 'react'
import { x, useUp } from '@xstyled/styled-components'
import logotype from '@pool/assets/logotype.svg'
import { Button } from '@pool/components/button/Button'
import { slideToId } from '@pool/utils/slideToId'
import { Link } from 'react-router-dom'

export function Navbar({ nav = true }) {
  const upMd = useUp('md')

  return (
    <x.div
      w={{ _: '100%', maxWidth: 'maxWidth' }}
      mx="auto"
      my="25px"
      px={{ _: '30px', md: '60px' }}
      id="navbar">
      <x.div
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        h="40px">
        <Link to="/">
          <x.img src={logotype} w="136px" title="linkpool" />
        </Link>
        {nav !== false && (
          <x.div display="flex" alignItems="center" gap="10">
            {upMd && (
              <x.nav display="flex" gap="10">
                <x.a
                  text="default"
                  cursor="pointer"
                  color={{ _: 'lime', hover: 'white' }}
                  onClick={() => slideToId('explore')}>
                  Data Centers
                </x.a>
                <x.a
                  text="default"
                  cursor="pointer"
                  color={{ _: 'lime', hover: 'white' }}
                  onClick={() => slideToId('infra')}>
                  Infrastructure
                </x.a>
                <x.a
                  text="default"
                  cursor="pointer"
                  color={{ _: 'lime', hover: 'white' }}
                  onClick={() => slideToId('b2b')}>
                  Solutions
                </x.a>
              </x.nav>
            )}
            <Button
              variant="default"
              size={!upMd ? 'sm' : 'default'}
              onClick={() => slideToId('footer')}>
              Contact Us
            </Button>
          </x.div>
        )}
      </x.div>
    </x.div>
  )
}
